import { httpOpen } from './configOpen'

export default {

    incluirPontoTokenTelefone: (ev) => {
        return httpOpen.post('incluirPontoToken', ev )
    },

    incluirPontoTokenEmail: (ev) => {
        return httpOpen.post('incluirPontoToken', ev )
    },

    incluirPontoTokenEndereco: (ev) => {
        return httpOpen.post('incluirPontoToken', ev )
    },

    incluirPontoCarne: (ev) => {
        return httpOpen.post('incluirPontoToken', ev )
    },

    salvaVolante: (ev) => {
        return httpOpen.post('salvaVolante/', ev )
    },


}
