export default function authHeader () {
  const user = JSON.parse(sessionStorage.getItem('user'))

  if (user && user.ResponseData) {
    return { token: user.ResponseData } // for Spring Boot back-end
    // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
  } else {
    return {}
  }
}
